class RiveroApi {

    static _api = null

    static get instance() {
      if (RiveroApi._api === null) {
        RiveroApi._api = new RiveroApi()
      }
      return RiveroApi._api
    }

    constructor(){
      this.baseURL = 'https://api.gruporivero.com/v1/';
      this.multimarcaURL = 'https://multimarca.gruporivero.com/'
      this.arr = [];
    }

    //TOKEN
    async getToken(){
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");

      var formdata = new FormData();
      formdata.append("grant_type", "password");
      formdata.append("client_id", "95d42dee-0c62-4f0e-a116-5540682870bd");
      formdata.append("client_secret", "Tbbs4uff8OW2PodlQLcQlUwboTQcJQ7lcIFdHSob");
      formdata.append("username", "ecasas2@gruporivero.com");
      formdata.append("password", "Rivero2022!");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      let tokensito = await fetch(this.multimarcaURL+"oauth/token", requestOptions)
      return tokensito.json();

    }

    //NUEVOS
    async getCar(data){
      console.log("Error: ", data)
      let responseCar = await fetch(this.baseURL+`cars/${data}`);

      let respuestas = await responseCar.json()

      const obj = {
        ...respuestas.data,
        colors: respuestas.data.colors.sort((a, b) => a.order - b.order),
        years: respuestas.data.years.sort((a, b) => parseInt(b.year) - parseInt(a.year)),
        versions: respuestas.data.versions.sort((a, b) => a.price - b.price),
      }
      
      return obj;

    }

    async getCars(data){
      //console.log("Estamos en riveroApi: ", data);
      let url = `cars?`;

      if(data && data.orderBy){
        url += `orderBy=${data.orderBy}&`
      }

      if (data && data.perPage) {
        url += `per_page=${data.perPage}&`;
      }

      if (data && data.filterMarcas) {
        /*console.log("RiveroApi filter MAKES: ", data.filterMarcas)
        const value = encodeURI(data.filterMarcas.join(","));
        url += `filter[make]=${value}&`;*///Es un array de marcas
        url += `filter[make]=${data.filterMarcas}&`; //Solo toma una marca
      }

      if (data && data.filterTipos) {
        console.log("RiveroApi filter TYPE: ", data.filterTipos)
        url += `filter[type]=${data.filterTipos}&`;
      }
      

      if (data && data.ultimoModelo) {
        url += `ultimo_modelo=1&`;
      }

      if (data && data.search) {
        url += `search=${data.search}&`;
      }

      if (data && data.mostRecent) {
        url += `most_recent=1&`;
      }

      let response = await fetch(this.baseURL+url);
      return response;

    }
    //SEMINUEVOS
    async getSeminuevosCars(data){
        
        let cadena = "";

        let url = "used-cars?filter[sucursal_make]=chevrolet&";
    
        if (data && data.filterSearch) {
          url += `search=${data.filterSearch}&`;
        }
    
        if (data && data.orderBy) {
          url += `orderBy=${data.orderBy}&`;
        }

        if (data && data.filterMarcas) {
          const value = encodeURI(data.filterMarcas.join(","));
          url += `filter[make]=${value}&`;
        }
    
        if (data && data.filterTipos) {
          //console.log("filterTipos: ", data.filterTipos)
          const value = encodeURI(data.filterTipos.join(","));
          url += `filter[type]=${value}&`;
        }
    
        if (data && data.perPage) {
          url += `per_page=${data.perPage}&`;
        }
        //console.log("RiveroApi: ", url);
        let response = await fetch(this.baseURL+url);
        return response.json();

    }

    async getSeminuevosCarId(id) {

      let response = await fetch(this.baseURL+`used-cars/`+id);
      return response.json();

    }

    async getMarcasCars(){
      let responseMarcas = await fetch(this.baseURL+`used-cars-makes`);

      return await responseMarcas.json()
    }
    //COTIZADOR
    async createQuotation(quotation) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var urlencoded = new URLSearchParams();
        urlencoded.append("car_id", quotation.car_id);
        urlencoded.append("entry_percentage", quotation.entry_percentage);
        urlencoded.append("months", quotation.months);
        urlencoded.append("warraty_id", quotation.warraty_id);
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        let Mensualidad = await fetch("https://api.gruporivero.com/v1/quotations", requestOptions);
        return Mensualidad.json();
    }
    //SEGUROS
    async getSegurosCar(carId) {
      let Seguros = await fetch(this.baseURL+`cars/${carId}/insurance`);
      
      return await Seguros.json()
    }
    //PROMOCIONES
    async getPromociones() {
      let responseMarcas = await fetch(this.baseURL+`promos?per_page=100`);

      return await responseMarcas.json()
    }
    //BLOGS
    async getBlogs(data){
     
      let url = `api/v1/posts/chevrolet?`;
  
      if (data && data.page) {
        url += `page=${data.page}&`;
      }
  
      if (data && data.orderBy) {
        url += `orderBy=${data.orderBy}&`;
      }

      let response = await fetch(this.multimarcaURL+url);
      return response.json();
      
    }

    async getBlog(blog){
      let responseBlog = await fetch(`https://multimarca.gruporivero.com/api/v1/posts/detail/${blog}`);

      return await responseBlog.json()
    }
    
    //TESTIMONIOS
    async getTestimonios() {
      let testimonios = await fetch(this.baseURL+`testimonios`);

      return await testimonios.json();
    }
    //TALLER DE SERVICIO
    async getPlanningTallerServicio(data){

      let dataToken = "";
      let tokenStorage = "";

      if(localStorage.getItem("token") === null){

        let tokensito = await this.getToken();
        localStorage.setItem("token", JSON.stringify(tokensito));
        dataToken = tokensito;
        //console.log("Obtenemos token por primera vez");

      } else {
        //console.log("Tenemos token pero checamos si expiro")
        tokenStorage = JSON.parse(localStorage.getItem("token")).access_token
        const jwtPayload = JSON.parse(window.atob(tokenStorage.split('.')[1]))
        const isExpired = Date.now() >= jwtPayload.exp * 1000;
        //console.log("Vacio", tokenStorage)
        
        if(isExpired === true){
          //Si expira el token, vuelve consulta uno nuevo.
          let tokensito = await this.getToken();
          localStorage.setItem("token", JSON.stringify(tokensito));
          dataToken = tokensito;
        }
      }

      //console.log("dataToken", JSON.parse(localStorage.getItem("token")).access_token)
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${ JSON.parse(localStorage.getItem("token")).access_token || dataToken}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      let planning = await fetch(this.multimarcaURL+`api/v1/planning?date=${data.day}&agency=${data.idSucursal}`, requestOptions)
      return planning.json();
    }
    async postPlanningTallerServicio(data){

      let dataToken = "";
      let tokenStorage = "";

      if(!localStorage.getItem("token")){
        let tokensito = await this.getToken();
        localStorage.setItem("token", tokensito.access_token);
        dataToken = tokensito.access_token;
        console.log("Vacio")
      }
      
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/javascript");
      myHeaders.append("Authorization", `Bearer ${ JSON.parse(localStorage.getItem("token")).access_token || dataToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "name": data.name,
        "date": data.date,
        "time": data.time,
        "agency": data.agency,
        "mobile": data.mobile,
        "model": data.model,
        "origin": "Web",
        "comment": data.comment,
        "ownerId": data.ownerId,
        "workType1": "Taller de Servicio",
        "workType2": null,
        "workType3": null,
        "workType4": null,
        "field1": ""
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      let postplanning = await fetch(this.multimarcaURL+`api/v1/planning`, requestOptions)
      return postplanning.json();
    }

    //TALLER DE SERVICIO NISSAN
    async postPlanningTallerServicioNissan(data){
      console.log("Estamos en el postPlanning de Nissan")
      let dataToken = "";
  
      if(!localStorage.getItem("token")){
        let tokensito = await this.getToken();
        localStorage.setItem("token", tokensito.access_token);
        dataToken = tokensito.access_token;
      }
      
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/javascript");
      myHeaders.append("Authorization", `Bearer ${ JSON.parse(localStorage.getItem("token")).access_token || dataToken}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        "name": data.name,
        "date": data.date,
        "time": data.time,
        "agency": data.agency,
        "mobile": data.mobile,
        "model": data.model,
        "origin": "Web",
        "comment": data.comment,
        "ownerId": data.ownerId,
        "workType1": "Taller de Servicio",
        "workType2": null,
        "workType3": null,
        "workType4": null,
        "field1": ""
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      //console.log("Sucede lo siguiente: ", raw);
      let postplanning = await fetch(this.multimarcaURL+`api/v1/nissan/planning`, requestOptions);
      console.log("postplanning: Llega aquí");
      return postplanning.json();
    }
    
    async sendEmailLeads(data){

      console.log("EnCORREO:",data)

      let dataToken = "";
      let nombre_sucursal = "";
      let dir_sucursal = "";
      let tokenStorage = "";

      if(!localStorage.getItem("token")){
        let tokensito = await this.getToken();
        localStorage.setItem("token", tokensito.access_token);
        dataToken = tokensito.access_token;
      }

      var momenToken = JSON.parse(localStorage.getItem("token"))

      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/javascript");
      myHeaders.append("Authorization", `Bearer ${momenToken.access_token || dataToken}`);
      myHeaders.append("Content-Type", "application/json");

        switch(data.agency){
          case '531':
          nombre_sucursal = 'Rivero Contry';
          dir_sucursal = 'Av. Eugenio Garza Sada 600 Sur, Arroyo Seco, Nuevo León, CP 64740';
          break;
          case '532':
            nombre_sucursal = 'Rivero Las Torres';
            dir_sucursal = 'Av. Lázaro Cardenas 2514 Valle del Márquez, Nuevo León, CP 64790';
          break;
          case '533':
            nombre_sucursal = 'Rivero Valle';
            dir_sucursal = 'Av. José Vasconcelos 325-Ote, Del Valle, , Nuevo León, CP 66220';
          break;
          case '115281495': //GOMEZ MORIN
            nombre_sucursal= 'Gómez Morín';
            dir_sucursal = 'Avenida Gómez Morin 402, Villas de Aragón, San Pedro Garza Garcia, Nuevo León, CP 67273';
          break;
          case '141341484': // Humberto Lobo Alianza
            nombre_sucursal = 'Humberto Lobo Alianza';
            dir_sucursal = 'Av. Humberto Lobo #660 cruz con Rio Rhin, Col. Del Valle, San Pedro Garza Garcia, Nuevo León, CP 66220';
          break;
          case '382179229':  // Humberto Lobo
            nombre_sucursal = 'Rivero Humberto Lobo';
            dir_sucursal = 'Av. José Vasconcelos #1555 Entre Neil Armstrong y Av. De los Conquistadores Col. Del Valle, San Pedro Garza Garcia, Nuevo León, CP 66220';
          break;
          case '232812511': //Guadalupe
            nombre_sucursal = 'Rivero Guadalupe';
            dir_sucursal = 'Carretera a Reynosa, cruz con Ave. México Col. Industrial La Silla, Guadalupe, Nuevo León, CP 67199';
          break;
          case '1043193': //Linda Vista
            nombre_sucursal = 'Rivero Linda Vista';
            dir_sucursal = 'Av. Miguel Alemán No. 5400, Col. Torres de Linda Vista, Guadalupe, Nuevo León, CP 67138';
          break;
          case '402145072': //Santa Catarina
            nombre_sucursal = 'Rivero Santa Catarina';
            dir_sucursal = 'Blvd. Gustavo Díaz Ordaz 100-A, Col. La Fama, Santa Catarina, Nuevo León, CP 66100';
          break;
          default: nombre_sucursal = 'Rivero Linda Vista2';
        } 

        var raw = JSON.stringify({
          "to": data.mail,
          "clientName": data.name + " haz realizado tu cita en Taller con éxito.",
          "subject": "Tu Cita en Taller de Servicio Grupo Rivero",
          "body": "<h1> Información de tu cita: </h1>"+

          "<div style='max-width: 600px;background-color: white;border-top: 2px solid #2486fc; border-left: 1px solid #2486fc; border-radius: 15px; padding: 10px; box-shadow: 1px 2px 3px 1px;'>"+
          
            "<div style='display:flex; justify-content: center; align-items:center; text-align: center;'><img src='https://www.gruporivero.com/images/header-logo.png' style='margin-left: 30%' /></div><hr/>"+
            "<h2 style='color: black;text-align: center'> Resumen de Cita </h2><hr/>"+
            "<div>"+
              "<div style='display:flex;'><h3 style='color: black; font-weight:600;margin-right:5px;'>Nombre: </h3> <h4 style='color: black;'>"+ data.name +" </h4></div><hr/>"+
              "<div style='display:flex;'><h3 style='color: black; font-weight:600;margin-right:5px;'> Fecha: </h3> <h4 style='color: black;'>"+ data.date +" </h4></div><hr/>"+
              "<div style='display:flex;'><h3 style='color: black; font-weight:600;margin-right:5px;'> Hora: </h3> <h4 style='color: black;'>"+ data.time +" </h4></div><hr/>"+
              "<div style='display:flex;'><h3 style='color: black; font-weight:600;margin-right:5px;'> Sucursal: </h3> <h4 style='color: black;'>"+ nombre_sucursal +" </h4></div><hr/>"+
              "<div style='display:flex;'><h3 style='color: black; font-weight:600;margin-right:5px;'> Dirección: </h3> <h4 style='color: black;'>"+ dir_sucursal +" </h4></div><hr/>"+
              "<div style='display:flex;'><h3 style='color: black; font-weight:600;margin-right:5px;'> Auto: </h3> <h4 style='color: black;'>"+ data.model +" </h4></div>"+
              "<img src='https://qr-generator.rivero.services/?chl="+data.qr+"' style='align-self: center' /><hr/>"+
            "</div>"+  
          "</div>",
    
          "footer": "<h4>Saludos</h4>",
          "bcc": "jcruz@gruporivero.com"
        })

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      let mail = await fetch(this.multimarcaURL+`api/v1/send/email`, requestOptions)
      return raw;
    }

    async getClientInfo(data){

      console.log("Global: ",data.Correo)

      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

    let infoClient = await fetch(this.multimarcaURL+`api/v1/servicio/contact/cars?phone=${data.Telefono}&mail=${data.Correo}`, requestOptions)
    return infoClient.json();
    }

    async postLeadNissan(data){

      let correo = "";
      let num = "";

      const fechita = new Date();
      let split = fechita.toLocaleString().split('/');
      let ult = split[2].split(',');

      if (split[1].length == '1'){
        split[1] = '0'+split[1];
      }

      if (split[0].length == '1'){
        split[0] = '0'+split[0];
      }

      let fecha = ult[0]+'-'+split[1]+'-'+split[0]+ult[1];

      if (data.TipoContacto == "Email"){
        correo = data.Contacto;
      } else if (data.TipoContacto == "WhatsApp" || data.TipoContacto == "Telefono"){
        num = data.Contacto;
      }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "environment": "prod",
        "requestdate": fecha,
        "new_used": data.New,
        "make": data.Marca,
        "year": data.Year,
        "model": data.Modelo,
        "name_first": data.Nombre,
        "name_middle": data.Apellido1,
        "name_last": data.Apellido2,
        "email": correo,
        "phone": num,
        "comments": "me interesa",
        "origin": data.Origin,
        "url": data.Url,
        "dealer_id": data.SucursalId,
        "dealer_name": data.Sucursal
      });

      console.log(raw)

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      let lead = await fetch("https://multimarca.gruporivero.com/api/v1/seekop/adf/leads", requestOptions)
      console.log(lead);
      return lead.json();

    }

    //ADWORDS
    async getCampaign(slug) {
      let campaings = await fetch(this.baseURL+`campaigns/${slug}`);

      return await campaings.json();
    }

    //CARRO PROMOCIÓN
    async getCarroPromocion(slug) {
      let promociones = await fetch(this.multimarcaURL+`api/v1/promociones/nuevos/${slug}`);

      return await promociones.json();
    }
}

module.exports = RiveroApi.instance